import Vue from 'vue';
import VueRouter from 'vue-router';

// progress bar
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

NProgress.configure({ showSpinner: false });

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home'),
  },
  {
    path: '/joinus',
    name: 'JoinUs',
    component: () => import('@/views/JoinUs'),
  },
  {
    path: '/movtile',
    name: 'movtile',
    component: () => import('@/views/Movtile'),
  },
  { path: '/*', redirect:'/' },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

//路由前置守卫
router.beforeEach((to, from, next) => {
  NProgress.start();
  NProgress.done();
  next();
});

export default router;
