<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        url: `https://ktapi.movtile.com/user/wechat/jsapiTicket?url=${window.location.href}`,
      };
    },
    methods: {
      fetchData(url, data) {
        return fetch(url).then((response) => response.json()); // parses response to JSON
      },
      setShare(res) {
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: res.appId, // 必填，公众号的唯一标识
          timestamp: res.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.nonceStr, // 必填，生成签名的随机串
          signature: res.signature, // 必填，签名，见附录1
          jsApiList: [
            'onMenuShareWeibo',
            'onMenuShareQZone',
            'updateTimelineShareData',
            'updateAppMessageShareData',
          ],
        });

        const image = 'https://ketang-pub.movtile.com/res/official/wx-share-logo.jpg';
        const title = '幕童-全能视频创作“梦工厂”';
        const subTitle =
          '提供视频云端协作办公、数据传输存储、影视制作教育、创作者一站式资源共享等全流程产品服务。';
        const url = window.location.href;
        wx.ready(() => {
          wx.updateAppMessageShareData({
            title: title, // 分享标题
            desc: subTitle, // 分享描述
            link: url || window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: image, // 分享图标
            success: function () {
              // 设置成功
            },
          });
          wx.updateTimelineShareData({
            title: title, // 分享标题
            desc: subTitle, // 分享描述
            link: url || window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: image, // 分享图标
            success: function () {
              // 设置成功
            },
          });
        });
      },
    },
    mounted() {
      this.fetchData(this.url)
        .then((data) => {
          this.setShare(data.data);
        })
        .catch((error) => console.error(error));
    },
  };
</script>
<style>
  #app {
    font-family: PingFangSC-Light, Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: #f0f0f0;
  }
</style>
