import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// Swiper
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.min.css';

// 默认样式
import '@/assets/style/global.less';

// 动画
import 'animate.css';

// swiper
Vue.use(VueAwesomeSwiper)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
